import _ from 'lodash'

export default {
	data() {
		return {
			
		}
	},
	props: [],
	created(){
		
	},
	methods: {
		focus_at_end_contenteditable(el) {
		    el.focus();
		    if (typeof window.getSelection != "undefined" && typeof document.createRange != "undefined") {
		        var range = document.createRange();
		        range.selectNodeContents(el);
		        range.collapse(false);
		        var sel = window.getSelection();
		        sel.removeAllRanges();
		        sel.addRange(range);
		    } else if (typeof document.body.createTextRange != "undefined") {
		        var textRange = document.body.createTextRange();
		        textRange.moveToElementText(el);
		        textRange.collapse(false);
		        textRange.select();
		    }
		}
	},
	computed:{
		
	},
	components:{
		
	}
}