import _ from 'lodash'
import Filter_allfavoris from './filter_allfavoris.vue'
import Subcontent_parcelles from '../parcelle/parcelle_favoris.vue'
import Subcontent_surfaces from '../surfaces_page.vue'
import Subcontent_recherches from '../search_tableau.vue'
import Subcontent_sites from '../favoris.vue'

export default {
	data() {
		return {
			
		}
	},
	props: [],
	created(){
		
	},
	methods: {
		
	},
	computed:{
		
	},
	components:{
		Filter_allfavoris,
		Subcontent_parcelles,
		Subcontent_surfaces,
		Subcontent_recherches,
		Subcontent_sites
	}
}