import _ from 'lodash'
import moment from 'moment'
import axios from '../common/axios.js'
import draggable from 'vuedraggable'
import CalculsDechets from '../common/mixins/CalculsDechets.js'

import MenuSousMenu from '../common/ui/menu_sous-menu.vue'

import Select from '../common/forms/Select.vue'
import Input from '../common/forms/Input.vue'
import Checkbox from '../common/forms/Checkbox.vue'

import HtmlTricks from '../common/mixins/HtmlTricks.js'

export default {
	mixins: [CalculsDechets, HtmlTricks],
	data() {
		return {
			show                                :'all',
			search                              : null,
			list                                : [],
			showFiltres                         : false,
			showDechets                         : {},
			stat_methaniseurs_nbr_show          : true,
			stat_methaniseurs_cogeneration_show : true,
			stat_methaniseurs_injection_show    : true,
			sort								: 'created',
			sortDirection						: 'desc',
			edit_note                           : false,
		}
	},
	created(){
	},
	mounted(){
		this.list = this.getAllSearches()
		//AS--> Ajout un événement sur le body pour fermer la modale
		document.querySelector("#app_content").addEventListener('click', this.auto_close_modal);
		document.querySelector("#app_content").addEventListener('click', this.auto_close_edit_note);
	},
	beforeDestroy: function() {
		document.querySelector("#app_content").removeEventListener('click', this.auto_close_modal);
		document.querySelector("#app_content").removeEventListener('click', this.auto_close_edit_note);
	},
	watch:{
		'$store.state.user.recherches': function(){
			console.log('WATCH - $store.state.user.recherches')
			this.list = this.getAllSearches()
		},
		edit_note: function(after, before){

			let self = this

			//AS--> Met le focus sur le dic qui a la class .note.edit
			if(after){
				setTimeout(function(){
					let note = document.querySelector('.note.edit')
					if(note) self.focus_at_end_contenteditable(note)
				}, 100)
			}

			if(before){
				let note = document.querySelector('#note_' + before.slug_name).innerHTML
				if(note == '') note = null
				if(before.notes != note){
					before.notes = note

					//AS--> Demande la mise à jour de la recherche chez iNex
					this.$store.dispatch('send_recherche', before)
				}
			}
		}
	},
	methods: {
		auto_close_modal(event) {
			if (event.target.closest('.filtre_colonnes') == null) this.showFiltres = false
		},
		auto_close_edit_note(event) {
			if(this.edit_note){
				if (event.target.closest('.note') == null) this.edit_note = false
			}
		},
		getAllSearches(){
			let self = this
			let recherches = []

			let dechets_ids = this.dechetsListe ? this.dechetsListe.map(function(dechet){return dechet.id}) : null

			this.$store.state.user.recherches.forEach(function(recherche){
				
				if(recherche.nom != '__last__'){

					recherche.dechets = {}

					//AS--> Gestion des déchets
					if(recherche.stats?.stats_per_material?.length && dechets_ids){
						recherche.stats.stats_per_material.forEach(function(dechet){
							if(dechets_ids.includes(dechet._id)){
								if(dechet._id in recherche.dechets){
									recherche.dechets[dechet._id].tonnage += dechet.tonnage ?? 0
									recherche.dechets[dechet._id].methanogenic_potential += dechet.methanogenic_potential ?? 0
								}else{
									recherche.dechets[dechet._id] = {
										tonnage: dechet.tonnage ?? 0,
										methanogenic_potential: dechet.methanogenic_potential ?? 0
									}
								}
							}else{
								if('others' in recherche.dechets){
									recherche.dechets['others'].tonnage += dechet.tonnage ?? 0
									recherche.dechets['others'].methanogenic_potential += dechet.methanogenic_potential ?? 0
								}else{
									recherche.dechets['others'] = {
										tonnage: dechet.tonnage ?? 0,
										methanogenic_potential: dechet.methanogenic_potential ?? 0
									}
								}
							}
						})
					}

					//AS--> Gestion de la famille des méthaniseurs
					recherche.methaniseur = self.getFamilleMethaniseurs(recherche)

					recherches.push(recherche)
				}
			})

			return recherches
		},
		get_date(recherche){
			if (recherche) {
				moment.locale(this.$store.state.ui.langue.substr(0, 2));
				return moment(String(recherche.created)).format('D MMMM YYYY H:mm')
			}
			return '';
		},
		setRecherche(recherche){
			this.$store.commit('UI_SET_RECHERCHE_ID', recherche.nom)
			this.$store.dispatch('setSaveSearch', recherche)
		},
		deleteRecherche(recherche){
			let self = this;
			this.$dialog.confirm(this.$t('filter_fav_confirm'), {loader: true})
			.then(dialog => {
				//AS--> Demande la suppression du favoris en DB
				axios.post('api=webinex/deleteUserSearch', recherche)
				.then(function (response) {
					
					//AS--> Ferme l'ancienne boîte de dialogue
					dialog.close();

					//RZ--> Recharge les recherches dans le stores
					axios.get('api=inex/users/{user_email}/views/{view_code}/searches/').then(function (response) {
						self.$store.commit('USER_SET_RECHERCHES', response.data)
					})

					//AS--> En cas de succès, supprime la valeur en FO et ferme la modale
					if(response.data == 'Search deleted' || response.data == '"Search deleted"'){

						//AS--> Récupère toutes les recherches en mémoire
						let recherches = _.clone(self.$store.state.user.recherches);

						//AS--> Supprime de recherches celui dont l'id correspond avec celui qu'il faut supprimer
						_.remove(recherches, function(r) {return r.nom == recherche.nom;});

						//AS--> Met à jour le store avec la nouvelle version
						self.$store.commit('USER_SET_RECHERCHES', recherches)
						
					//AS--> Sinon on prévient de l'impossibilité de supprimer le favoris
					}else{
						self.$dialog.alert(this.$t('filter_fav_echec'));
					}
				}).catch(function (error) {})

			});
		},
		downloadRecherche(recherche){
			this.$store.dispatch('open_modal', 'export_list')
			this.$store.commit('UI_SET_EXPORT_ID', recherche.id)
		},
		unsetRechercheDefaut(recherche){
			let self = this

			recherche.pardefaut = false
			recherche.update    = true

			//AS--> Demande la suppression du favoris en DB
			axios.put('api=inex/users/{user_email}/views/{view_code}/searches/'+recherche.slug_name, recherche)

			//AS--> Récupère toutes les recherches en mémoire
			let recherches = _.clone(self.$store.state.user.recherches);

			//AS--> Modifie leur statut de recherche par défaut
			recherches.forEach(function(rech){
				rech.pardefaut = 0
			})

			//AS--> Met à jour le store avec la nouvelle version
			self.$store.commit('USER_SET_RECHERCHES', recherches)
		},
		setRechercheDefaut(recherche){
			let self = this

			recherche.pardefaut = true
			recherche.update    = true

			//AS--> Demande la suppression du favoris en DB
			axios.put('api=inex/users/{user_email}/views/{view_code}/searches/'+recherche.slug_name, recherche)

			//AS--> Récupère toutes les recherches en mémoire
			let recherches = _.clone(self.$store.state.user.recherches);

			//AS--> Modifie leur statut de recherche par défaut
			recherches.forEach(function(rech){
				rech.pardefaut = rech.nom == recherche.nom ? 1 : 0
			})

			//AS--> Met à jour le store avec la nouvelle version
			self.$store.commit('USER_SET_RECHERCHES', recherches)
		},
		getRechercheById(recherche_nom){
			let recherche = null
			this.$store.state.user.recherches.forEach(function(_recherche){
				if(_recherche.nom && _recherche.nom == recherche_nom) recherche = _recherche
			})
			return recherche
		},

		exportXls(context){
			this.$store.commit('UI_SET_EXPORT_ID', context.id)
			this.$store.commit('UI_SET_EXPORT_TYPE', 'csv')
			this.$store.dispatch('open_modal', 'export_list')
		},

		exportCrm(context){
			this.$store.commit('UI_SET_EXPORT_ID', context.id)
			this.$store.commit('UI_SET_EXPORT_TYPE', 'crm')
			this.$store.dispatch('open_modal', 'export_list')
		},
		openPendingFlowModal(promise) {
			let self = this

			let trad_code = 'waste_dashboard'

			self.$store.dispatch('open_modal', {
				code: 'pending_api', params: {
					promise,					
					pending_title: 'modal_export_pending_title_' + trad_code,
					pending_message: 'modal_export_pending_message_' + trad_code,
					success_title: 'modal_export_success_title_' + trad_code,
					success_message: 'modal_export_success_message_' + trad_code,
					error_title: 'modal_export_error_title_' + trad_code,
					error_message: 'modal_export_error_message_' + trad_code,
					error_html: '<p>' + self.$t('modal_export_error_message_contact_' + trad_code) + '<br><a href="mailto:' + self.$t('modal_export_error_message_contact_mail_' + trad_code) + '">' + self.$t('modal_export_error_message_contact_mail_' + trad_code) + '</a></p>'
				}
			})
		},
		exportMatieres(context){
			let self = this
			this.$store.commit('UI_SET_EXPORT_ID', context.id)
			const promise = axios.post("api=inex/companies/export/xls/{view_code}/waste_dashboard", self.$store.getters.getFiltersForExport, {
				headers: {'Content-Type': 'application/json', 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
					responseType: 'arraybuffer'
			}).then(function (response) {
				return Promise.resolve(response)
			}).catch(function (error) {
				return Promise.reject(error)
			})
			// .finally(function(){
			// 	self.$store.dispatch('open_modal', 'export_matieres_ok')
			// })

			this.openPendingFlowModal(promise)	
		},
		getFamilleMethaniseurs(recherche){
			let famille = null
			if(recherche.stats?.stats_familles?.length){
				recherche.stats.stats_familles.forEach(function(fam){
					if(fam.fid == 1 && 'capacites' in fam && fam.capacites.length){
						famille = {
							nbr              : fam.nbr,
							cogeneration     : null,
							cogeneration_942 : null,
							injection        : null
						}
						fam.capacites.forEach(function(capacite){
							if(capacite.cid == 106) famille.cogeneration 	 = capacite.val.somme
							if(capacite.cid == 107) famille.injection 		 = capacite.val.somme
							if(capacite.cid == 942) famille.cogeneration_942 = capacite.val.somme
						})
						if(famille.cogeneration_942) famille.cogeneration = famille.cogeneration_942
					}
				})
			}
			return famille
		},
		setSort(key){
			if(this.sort == key){
				this.sortDirection = this.sortDirection == 'asc' ? 'desc' : 'asc'
			}else{
				this.sortDirection = 'asc'
				this.sort = key
			}
		},
		getZones(recherche){
			if(recherche.stats?.extended_layers?.length){
				let layers = recherche.stats.extended_layers.map(function(zone){
					return zone.properties?.nom
				})

				//AS--> Retire les éléments vides de l'array
				layers = layers.filter(function(zone){
					return zone
				})

				//AS--> Retourne la liste des zones
				return layers.join(', ')
			}
		},
		menu_outils(item){
			let menu = {
				menu: {
					picto: 'Action',
					label: false
				},
				sousmenu: {
					list: []
				}
			}

			//AS--> Supprimer le favoris
			menu.sousmenu.list.push({
				picto: 'Trash2',
				label: this.$t('search_supprimer_recherche'),
				action: this.deleteRecherche,
				width: 18,
				height: 18
			})

			//AS--> Epingler le favoris
			if(item.pardefaut == 0){
				menu.sousmenu.list.push({
					picto: 'Pin',
					label: this.$t('search_set_recherche_favorite'),
					action: this.setRechercheDefaut,
					width: 15,
					height: 15
				})
			}

			//AS--> desépingler le favoris
			if(item.pardefaut == 1){
				menu.sousmenu.list.push({
					picto: 'Pin',
					label: this.$t('search_unset_recherche_favorite'),
					action: this.unsetRechercheDefaut,
					width: 15,
					height: 15
				})
			}

			return menu
		},
		isFromUser(recherche){
			return recherche.email == this.$store.state.user.user.email
		},
	},
	computed:{
		getDefaut(){
			let self = this
			let defaut = null
			this.$store.state.user.recherches.forEach(function(recherche){
				if(recherche.pardefaut && (self.show == 'all' || self.show == self.$store.state.user.user.email) && recherche.email == self.$store.state.user.user.email) defaut = recherche
			})
			return defaut
		},
		getPasDefaut(){
			let self = this
			let recherches = []
			this.$store.state.user.recherches.forEach(function(recherche){
				if(self.show == 'all' || self.show == recherche.email){
					if((!recherche.pardefaut || recherche.email != self.$store.state.user.user.email) && recherche.nom != '__last__') recherches.push(recherche)
				}
			})
			recherches = _.orderBy(recherches, ['created'], ['desc']);
			return recherches
		},
		utilisateurs(){
			let utilisateurs = []
			this.$store.state.user.recherches.forEach(function(recherche){
				utilisateurs.push(recherche.email)
			})
			//AS--> On supprime les doublons et on trie
			return [...new Set(utilisateurs)].sort();
		},
		utilisateurs_select(){
			let utilisateurs = []
			if(this.utilisateurs && this.utilisateurs.length){
				this.utilisateurs.forEach(function(utilisateur){
					utilisateurs.push({
						label: utilisateur,
						value: utilisateur
					})
				})
			}
			return utilisateurs
		},
		hasMethaniseurs(){
			return this.$store.getters.canShowPotentielMeta
			// let self = this
			// let has = false
			// this.list.forEach(function(recherche){
			// 	if(self.getFamilleMethaniseurs(recherche)) has = true
			// })
			// return has
		},
		dechetsListe(){
			let liste = []
			if(this.$store.getters.fonctionnement != 'dechets') return liste

			let dechets = _.cloneDeep(this.$store.state.ui.dechets)
			if(dechets?.length){
				dechets = dechets[0].childs
				_.forEach(dechets, function(dechet){
					if(dechet.data.colonne_recherches){
						liste.push({
							id         : dechet.data.id,
							titre      : dechet.data.titre,
							definition : dechet.data.definition ?? null
						})
					}
				})
			}

			if(liste.length){
				liste.push({
					id: 'others',
					titre: this.$t('Autres')
				})
			}

			//AS--> Mets à jour le showDechets
			liste.forEach(function(dechet){
				if(!(dechet.id in this.showDechets)) this.$set(this.showDechets, dechet.id, true)
			}.bind(this))

			return liste.length ? liste : null
		},
		filteredSortedList(){
			let self = this
			
			let list = _.cloneDeep(this.list)
			let recherches = []

			//AS--> Filtre sur l'utilisateur
			list.forEach(function(recherche){
				if(self.show == 'all' || self.show == recherche.email){
					recherches.push(recherche)
				}
			})

			//AS--> Filtre sur le nom de la recherche
			if(this.search){
				let search = String(this.search).toLowerCase()
				recherches = recherches.filter(function(recherche){
					return recherche.nom ? String(recherche.nom).toLowerCase().includes(search) : false
				})
			}

			//AS--> Ordonne en fonction de this.sort et this.sortDirection
			recherches = _.orderBy(recherches, [
				//AS--> Sort suivant la recherche par défaut
				function(recherche){
					return recherche.pardefaut && recherche.email == this.$store.state.user.user.email ? 1 : 0
				}.bind(this), 
				//AS--> Sort suivant critère de tri
				function(recherche){
					let value = _.get(recherche, this.sort)
					//AS--> Converti les string en kebabCase pour un meilleur classement
					if(typeof value == 'string') return _.kebabCase(value)
					//AS--> Converti les valeurs undefined en null ou false suivant la direction pour un meilleur classement
					if(typeof value == 'undefined') return this.sortDirection == 'asc' ? null : false
					return value
				}.bind(this)
			], ['desc', this.sortDirection]);

			return recherches
		},
		countColMethaniseurs(){
			let nbr = 0;
			if(this.stat_methaniseurs_nbr_show) nbr++
			if(this.stat_methaniseurs_cogeneration_show) nbr++
			if(this.stat_methaniseurs_injection_show) nbr++
			return nbr
		},
		menu_telecharger(){
			let menu = {
				menu: {
					picto: 'Download2',
					label: false
				},
				sousmenu: {
					list: []
				}
			}

			//AS--> Export en Xls
			if(this.$store.getters.canExportCSV){
				menu.sousmenu.list.push({
					picto: 'Xls',
					label: this.$t('statut_bar_export'),
					action: this.exportXls,
					width: 18,
					height: 18
				})
			}

			//AS--> Export en Xls
			if(this.$store.getters.canExportCRM){
				menu.sousmenu.list.push({
					picto: 'Crm',
					label: this.$t('statut_bar_export_crm'),
					action: this.exportCrm,
					viewBox: '0 0 17 13',
					width: 17,
					height: 13
				})
			}

			//AS--> Export des matières
			if(this.$store.getters.canExportMatieres){
				menu.sousmenu.list.push({
					picto: 'Waste',
					label: this.$t('statut_bar_export_matieres'),
					action: this.exportMatieres,
					viewBox: '-2 0 52 46',
					width: 18,
					height: 18
				})
			}

			return menu
		},
		show_potentiel_metha(){
			return this.$store.state.ui.sourcing.affiche_potentiel_metha_dans_recherches
		}
	},
	components: {
		SousMenu: () => import('./sous_menu.vue'),
		MenuSousMenu,
		Select,
		Input,
		Checkbox,
		draggable,
	}
}